import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-date-tracking',
  templateUrl: './date-tracking.component.html',
  styleUrls: ['./date-tracking.component.scss']
})
export class DateTrackingComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }

  backBtnDate(){
    // alert("123456789")
    this.router.navigate(['track/month']);
  }

  showDateToIns(){
    this.router.navigate(['track/insurer']);
  }

}
