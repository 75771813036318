import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-not-connect',
  templateUrl: './not-connect.component.html',
  styleUrls: ['./not-connect.component.scss']
})
export class NotConnectComponent implements OnInit {

	public constructor(private router: Router, private route: ActivatedRoute) {

	}

  ngOnInit() {
  }

  backButton(){
    console.log("hello!");
    this.router.navigateByUrl('pushTicket/ticket-management');
  }

}
