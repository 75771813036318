import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-insurer-tracking',
  templateUrl: './insurer-tracking.component.html',
  styleUrls: ['./insurer-tracking.component.scss']
})
export class InsurerTrackingComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }

  backBtnTwo(){
    this.router.navigate(['track/date']);
  }

}
