import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Analytics',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'SALES OVERVIEW',
        type: 'item',
        url: '/dashboard/analytics',
        icon: 'feather icon-home'
      },
      {
        id: 'dashboard',
        title: 'TICKET OVERVIEW',
        type: 'item',
        url: '/ticket/ticket-overview',
        icon: 'feather icon-search'
      }
    ]
  },
  {
    id: 'Leads',
    title: 'Leads Management System',
    type: 'group',
    icon: 'feather icon-layers',
    children: [
      {
        id: 'basic',
        title: 'Leads',
        type: 'collapse',
        icon: 'feather icon-box',
        children: [
          {
            id: 'lead',
            title: 'UPDATED SALES',
            type: 'item',
            url: '/lead/list'
          },
          {
            id: 'lead',
            title: 'BULK UPLOAD',
            type: 'item',
            url: '/lead/upload'
          },
          {
            id: 'lead',
            title: 'OFFLINE SALES',
            type: 'item',
            url: '/lead/create'
          }
        ]
      }
    ]
  },
  {
    id: 'User',
    title: 'User Management System',
    type: 'group',
    icon: 'feather icon-users',
    children: [
      {
        id: 'basic',
        title: 'User',
        type: 'collapse',
        icon: 'feather icon-user',
        children: [
          {
            id: 'user',
            title: 'List',
            type: 'item',
            url: '/user/list'
          },
          {
            id: 'user',
            title: 'Create user',
            type: 'item',
            url: '/user/create'
          }
        ]
      }
    ]
  },
  {
    id: 'Tracking',
    title: 'track agents',
    type: 'group',
    icon: 'feather icon-users',
    children: [
      {
        id: 'basic',
        title: 'Tracking',
        type: 'collapse',
        icon: 'feather icon-user',
        children: [
          {
            id: 'tracking',
            title: 'Agent Tracking',
            type: 'item',
            url: '/tracking/agent/hourly'
          }
        ]
      }
    ]
  },
  {
    id: 'User',
    title: 'Search Engine',
    type: 'group',
    icon: 'feather icon-users',
    children: [
      {
        id: 'basic',
        title: 'Search Pannel',
        type: 'collapse',
        icon: 'feather icon-search',
        children: [
          {
            id: 'user',
            title: 'QUICK SEACRH',
            type: 'item',
            url: '/quick-search'
          },
        ]
      }
    ]
  }
];

const NavigationItemsForAgents = [
  {
    id: 'navigation',
    title: 'Analytics',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'BUSINESS OVERVIEW',
        type: 'item',
        url: '/dashboard/analytics',
        icon: 'feather icon-home'
      },
      {
        id: 'dashboard',
        title: 'TICKET OVERVIEW',
        type: 'item',
        url: '/ticket/ticket-overview',
        icon: 'feather icon-search'
      }
    ]
  },
  {
    id: 'Leads',
    title: 'Insurance And Business Record',
    type: 'group',
    icon: 'feather icon-layers',
    children: [
      {
        id: 'basic',
        title: 'Leads',
        type: 'collapse',
        icon: 'feather icon-box',
        children: [
          {
            id: 'UPDATED SALES',
            title: 'List',
            type: 'item',
            url: '/lead/list'
          }
        ]
      }
    ]
  },
  {
    id: 'User',
    title: 'Search Engine',
    type: 'group',
    icon: 'feather icon-users',
    children: [
      {
        id: 'basic',
        title: 'Search Pannel',
        type: 'collapse',
        icon: 'feather icon-search',
        children: [
          {
            id: 'user',
            title: 'QUICK SEACRH',
            type: 'item',
            url: '/quick-search'
          },
        ]
      }
    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    //console.log(localStorage.getItem('role'))
    if (localStorage.getItem('role') === '1') {
      return NavigationItems;
    } 
    else if (localStorage.getItem('role') === '2' || localStorage.getItem('role') === '3') {
      return NavigationItemsForAgents;
    }
  }
}
