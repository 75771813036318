import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-month-tracking',
  templateUrl: './month-tracking.component.html',
  styleUrls: ['./month-tracking.component.scss']
})
export class MonthTrackingComponent implements OnInit {

  public constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    
  }

  showDays(){
    this.router.navigate(['track/date']);
  }

  backBtnMonth(){
    this.router.navigate(['tracking/agent/hourly']);
  }

}


