import { Component, OnInit } from '@angular/core';
import { ChartDB } from '../../fack-db/chart-data';
import {ApexChartService} from '../../theme/shared/components/chart/apex-chart/apex-chart.service';
import { BackendCallService } from '../../services/backend-call.service'

@Component({
  selector: 'app-ticket-overview',
  templateUrl: './ticket-overview.component.html',
  styleUrls: ['./ticket-overview.component.scss']
})
export class TicketOverviewComponent implements OnInit {

  // constructor() { }

  // ngOnInit() {
  // }

    public chartDB: any;
  public dailyVisitorStatus: string;
  public dailyVisitorAxis: any;
  public deviceProgressBar: any;
  public role: any;
  public count: any;

  constructor(public apexEvent: ApexChartService,private backendCall: BackendCallService) {
    this.chartDB = ChartDB;
    this.dailyVisitorStatus = '1y';

    this.deviceProgressBar = [
      {
        type: 'success',
        value: 66
      }, {
        type: 'primary',
        value: 26
      }, {
        type: 'danger',
        value: 8
      }
    ];

    this.role = localStorage.getItem('role');
    console.log(localStorage.getItem('role'),"jjjjjj");
  }



  public ngOnInit(): void {
    this.getCountRoleWise();


	}

  public getCountRoleWise(): any {
    this.backendCall.getLeadCount().subscribe(Response => {
			if (Response) {
        this.count = Response.data[0];

			}
		},
			error => {
				alert(error.error.error_msg);
			});
  }

}


// }
// import { Component, OnInit } from '@angular/core';
// import { ChartDB } from '../../../fack-db/chart-data';
// import {ApexChartService} from '../../../theme/shared/components/chart/apex-chart/apex-chart.service';
// import { BackendCallService } from '../../../services/backend-call.service'

// @Component({
//   selector: 'app-dash-analytics',
//   templateUrl: './dash-analytics.component.html',
//   styleUrls: ['./dash-analytics.component.scss']
// })
// export class DashAnalyticsComponent implements OnInit {
//   public chartDB: any;
//   public dailyVisitorStatus: string;
//   public dailyVisitorAxis: any;
//   public deviceProgressBar: any;
//   public role: any;
//   public count: any;

//   constructor(public apexEvent: ApexChartService,private backendCall: BackendCallService) {
//     this.chartDB = ChartDB;
//     this.dailyVisitorStatus = '1y';

//     this.deviceProgressBar = [
//       {
//         type: 'success',
//         value: 66
//       }, {
//         type: 'primary',
//         value: 26
//       }, {
//         type: 'danger',
//         value: 8
//       }
//     ];

//     this.role = localStorage.getItem('role');
//     console.log(localStorage.getItem('role'),"jjjjjj");
//   }



//   public ngOnInit(): void {
//     this.getCountRoleWise();


// 	}

//   public getCountRoleWise(): any {
//     this.backendCall.getLeadCount().subscribe(Response => {
// 			if (Response) {
//         this.count = Response.data[0];

// 			}
// 		},
// 			error => {
// 				alert(error.error.error_msg);
// 			});
//   }

// }

