import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-track-dispositon',
  templateUrl: './track-dispositon.component.html',
  styleUrls: ['./track-dispositon.component.scss']
})
export class TrackDispositonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
